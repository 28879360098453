.text {
  .variant(left; {
    text-align: left;
  });

  .variant(center; {
    text-align: center;
  });

  .variant(right; {
    text-align: right;
  });
}