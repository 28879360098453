.grid {
  box-sizing: border-box;
  padding: 0 (@col-gutter / 2);

  .variant(container; {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
  });

  .variant(no-gutters, {
    padding: 0;
  });
}