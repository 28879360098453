/* Text */
.text {

    /* Blockquotes */
    blockquote {
        padding: 1em 1.5em;
        margin-left: 0;
        margin-right: 0;
        background: rgba(175,175,175,.1);
        border-left: 2px solid @main-color;
    }

    b,strong {
        font-weight: 500;
    }

    /* Links */
    a {
        color: @main-color;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    /* Images */
    img {
        max-width: 100%;
        display: static;
    }

    /* Headers */
    h1, h2, h3, h4, h5, h6 {
        font-family: @title-font-family;
        font-weight: @title-font-weight;
        line-height: @title-line-height;
    }
    h1 {
        font-size: @title-font-size;
    }
    h2 {
        font-size: @title-font-size * .95;
    }
    h3 {
        font-size: @title-font-size * .88;
    }
    h4 {
        font-size: @title-font-size * .81;
    }
    h5 {
        font-size: @title-font-size * .74;
    }
    h6 {
        font-size: @title-font-size * .67;
    }

    /* Tables */
    table {
        font-size: 0.9em;
        width: 100%;
        border: none;
        border-collapse: collapse;
    }
    td, th {
        border: 1px solid #e7e7e7;
        padding: .8em 1.2em;
    }
    thead td,
    th {
        background: @main-color;
        color: #fff;
        font-weight: @text-font-weight;
        border: 1px solid @main-color;
        border-right: 1px solid rgba(255,255,255,.2);
        text-align: left;

        &:last-child {
            border-right: 1px solid @main-color;
        }
    }
    tbody tr td {
        background: rgba(0,0,0,.05);
    }
    .table-container {
        min-height: .01%;
        overflow-x: auto;
    }

    /* Lists */
    ul, ol {
        padding-left: 1.9em;

        li {
            list-style-type: none;
            margin-top: .5em;
            margin-bottom: .5em;

            &::before {
                display: inline-block;
                line-height: 1;
                margin-left: -1.5em;
                width: 1.5em;
                color: @main-color;
            }

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

    }
    ul {

        li {

            &::before {
                line-height: @text-line-height;
                font-size: 1.2em;
                content: "•";
            }
        }
    }

    ol {
        counter-reset: list;

        li {

            &::before {
                margin-left: -2em;
                width: 2em;
                content: counter(list) ".";
                counter-increment: list;
            }
        }
    }

    p, blockquote, ul, ol, table, h1, h2, h3, h4, h5, h6, .table-container {
        .vmargin(1em);
    }
}
.text--columns-2 {

    .responsive-min(544px, {
        .columns(2);

        p, blockquote, ul, ol, li, table, h1, h2, h3, h4, h5, h6, .table-container {
            page-break-inside: avoid;
            -webkit-column-break-inside: avoid;
            break-inside: avoid;
        }
    });
}

/* Selection */
::-moz-selection {
    background: @main-color;
    color: #fff;
    text-shadow: none;
}
::selection {
    background: @main-color;
    color: #fff;
    text-shadow: none;
}