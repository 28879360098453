/* Product */
.product {
    display: block;
    width: 100%;
    max-width: 213px;
    margin-left: auto;
    margin-right: auto;

    &::after {
        .transition;
        z-index: 601;
        position: relative;
        display: inline-block;
        width: 2.4rem;
        height: 2.4rem;
        content: "";
        background-color: @alt-color;
        margin-left: ~"calc(50% - 1.2rem)";
        margin-top: .9rem;
        transform: rotate(45deg);
        opacity: 0;
        box-shadow: -1px -1px 0 rgba(0,0,0,.07);

        .responsive-max(767px, {
            z-index: 501;
        });
    }

    &.active {

        &::after {
            opacity: 1;
        }

        & + .products-list__info {
            opacity: 1;
            visibility: visible;
        }
    }
}
.product__link {
    display: block;

    &:hover,
    &:focus {
        outline: none;

        .product__image[data-select] {

            &::before,
            &::after {
                opacity: 1;
            }
        }
    }
}
.product__image {
    .vmargin(6px);
    position: relative;

    img {
        width: 100%;
    }

    &[data-select] {

        &::before {
            .transition;
            .absolute-block(100%,100%);
            left: 0;
            top: 0;
            background-image: linear-gradient(to top, rgba(0,0,0,.7) 0%, rgba(0,0,0,.2) 100%);
            opacity: 0;
        }

        &::after {
            .transition;
            .absolute-block(100%,auto);
            top: 50%;
            margin-top: -10px;
            content: attr(data-select);
            text-align: center;
            color: #fff;
            opacity: 0;
        }
    }
}
.product__title {
    .vmargin(2px);
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.product__author {
    .vmargin(2px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.product__descr {
    .vmargin(2px);
}
.product__info {
    .transition;
    position: absolute;
    z-index: 600;
    transform: translate3d(0,0,0);
    left: 0;
    width: 100%;
    margin-top: 2rem;
    box-shadow: 0 0 1px rgba(0,0,0,.3);
    opacity: 0;
    visibility: hidden;

    .responsive-max(767px, {
        z-index: 500;
    });
}