/* Instagram feed */
.section--instagram-feed {}
.section__inner--instagram-feed {}
.instagram-feed {}
.instagram-feed__row {}
.instagram-feed__col {}
.instagram-feed__item {
    /*width: 500px;
    transform: scale(.6);
    transform-origin: top left;*/
    width: 100%;
}