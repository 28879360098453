/* Author */
.author {
    text-align: center;
}
.author--main {
    text-align: left;
}
.author__image {
    .vmargin(1.5rem);
    max-width: 132px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    overflow: hidden;
    display: block;

    img {
        width: 100%;
    }
}
.author__title {
    .vmargin(2px);
    font: @title-font;
    font-size: 1.3rem;
}
.author__descr {
    .vmargin(2px);
}
.author__tags {
    .vmargin(2px);
    color: @light-color;
}
.author__tags--line {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.author__footer {
    .vmargin(2rem);
}
.author__params {
    .vmargin(2.5rem);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-left: -.5rem;
    margin-right: -.5rem;
}
.author__params-section {
    margin: .5rem;
    flex: 0 1 46%;
}
.author__params-section-title {
    .vmargin(.7rem);
    color: @light-color;
}
.author__params-section-content {
    .vmargin(.7rem);
}
.author__social {
    .vmargin(3rem);
}
.author__text {}
.author__text--left {
    text-align: left;
}
.author__text--short {
    max-height: 7.5em;
    overflow: hidden;
    position: relative;

    &::after {
        .absolute-block(100%,1.5em);
        .hgradient(rgba(255,255,255,0), #fff);
        bottom: 0;
        right: 0;
    }
}
.author__subscribe {
    .vmargin(3.5rem);
}