/* Ajax preloader */
.ajax-preloader {
    .transition(opacity .3s linear);
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    background: rgba(255,255,255,.8);
    opacity: 0;
    visibility: hidden;

    &::after {
        .absolute-block(50px,50px);
        left: 50%;
        top: 50%;
        margin-top: -25px;
        margin-left: -25px;
        border: 1px solid rgba(0,0,0,.3);
        border-bottom: 1px solid rgba(0,0,0,.9);
        border-radius: 25px;
        animation: ajax-preloader 1s infinite linear;
    }

    &.active {
        opacity: 1;
        visibility: visible;
    }
}
@-webkit-keyframes ajax-preloader {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes ajax-preloader {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}