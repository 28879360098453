/* Products */
.section--products {
    background-color: #fff;
    padding-bottom: 9rem;

    .responsive-max(767px, {
        padding-top: 5rem;
        padding-bottom: 5rem;
    });
}
.section__inner--products {}
.products {
    position: relative;
}
.products__categories {
    .vmargin(2.8rem);
    position: relative;
    overflow: hidden;

    .responsive-max(767px, {
        font-size: 2.4rem;
        line-height: 1.7;

        &::after {
            .absolute-block(100%,100%);
            z-index: 2;
            left: 0;
            top: 0;
            background-image: linear-gradient(to right, #fff 0%, rgba(255,255,255,0) 30%, rgba(255,255,255,0) 70%, #fff 100%);
            pointer-events: none;
        }
    });
}
.products__container {
    position: relative;
}
.products__row {}
.products__col {}
.products__col--filter {
    display: flex;
    align-items: stretch;
}
.products__filter {
    width: 100%;
}
.products__col--products {}
.products__list {

    .ajax-preloader {
        z-index: 501;
    }

    &:not(.products__list--small) {

        .products__list-row {

            .responsive-min(544px, {
                margin-top: 0;
                margin-bottom: 0;
            });
        }
        .products__list-col {

            .responsive-min(544px, {
                padding-top: 0;
                padding-bottom: 0;
            });
        }
    }
}
.products__list--small {

    .products__list-row {
        margin: -2rem -(2rem / 2) 2rem -(2rem / 2);

        &:last-child {
            margin-bottom: 0;
        }
    }

    .products__list-col {
        padding: 2rem (2rem / 2) 0 (2rem / 2);

        .responsive-min(992px, {

            &.col--lg-2 {
                width: 20%;

                &:nth-child(6) {
                    display: none;
                }
            }
        });

        .responsive(768px, 991px, {

            &.col--md-3 {

                &:nth-child(5),
                &:nth-child(6) {
                    display: none;
                }
            }
        });

        .responsive-max(767px, {

            &.col--sm-6 {

                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6) {
                    display: none;
                }
            }
        });
    }

    .products__item {
        .vmargin(1rem);

        &::after {
            display: none;
        }
    }
}
.products__list-row {}
.products__list-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.products__item {
    //.vmargin(2.5rem);
}
.products__footer {
    .vmargin(4rem);
    text-align: center;
}
.products__overlay {
    .transition;
    z-index: 598;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: ~"calc(100% + 100px)";
    background: rgba(0,0,0,.5);
    opacity: 0;
    pointer-events: none;

    &.active {
        opacity: 1;
        pointer-events: auto;
    }

    .responsive-max(767px, {
        z-index: 498;
        max-width: none;
    });
}