/* Categories */
.categories {
    font: @title-font;
    font-size: 3rem;
    line-height: 1.5;
    display: flex;
    //align-items: center;
    flex-wrap: wrap;
    margin-left: -.3rem;
    margin-right: -.3rem;

    .responsive-max(991px, {
        font-size: 2.6rem;
    });

    .slick-list {
        overflow: visible !important;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
    }
}
.categories__item {
    .transition;
    display: block;
    text-align: center;
    margin: .4rem .3rem;

    &:last-child {
        margin-right: 0;
    }

    &.slick-slide {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-right: 0;
    }
}
.categories__item-link {
    display: inline-block;
    border-bottom: 3px solid transparent;
    background: none;
    padding: 0;
    color: @light-color;

    &:hover,
    &:focus {
        outline: none;
        color: @main-color-hover;
    }
    &.current {
        color: @text-color;
        border-color: #0d0d0d;

        &:hover,
        &:focus {
            color: @main-color-hover;
            border-color: @main-color-hover;
        }
    }
}
.categories--wrap {
    flex-wrap: wrap;
}
.categories--popup {
    font: inherit;

    .categories__item-link {
        color: #777;

        &:hover {
            color: @main-color-hover;
        }

        &.current {
            color: #fff;
            border-color: #fff;

            &:hover {
                color: @main-color-hover;
                border-color: @main-color-hover;
            }
        }
    }
}