/* Button */
.button {
    .transition;
    padding: 0 1.8rem;
    font: @text-font;
    font-size: 1.3rem;
    color: @text-color;
    text-align: center;
    cursor: default;
    text-decoration: none;
    height: 3rem;
    line-height: 3rem;
    background-color: transparent;
    border: 1px solid #e2e2e2;
    border-radius: 1.5rem;
    white-space: nowrap;
    user-select: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .ie & {
        display: inline-block;
    }

    &:hover,
    &:focus,
    &.loading {
        border-color: @alt-color-hover;
        background-color: @alt-color-hover;
        outline: none;
        text-decoration: none;
    }
    &:active {
        transform: translateY(1px);
    }

    &.disabled,
    &[disabled] {
        opacity: .7;
        cursor: not-allowed;
        pointer-events: none;
    }

    &.loading {
        animation: fadeOut 1s linear infinite;
    }
}
.button__icon {
    .hmargin(.8rem);
    font-size: 1.6rem;
    height: 1.6rem;
    line-height: 1;
}
.button__icon--brand {
    color: @main-color;
}
.button__label {
    flex: 1 1 100%;
    text-align: center;
}

.button--white {
    background-color: #fff;
    border-color: #fff;
    color: #000;
}
.button--dark {
    background-color: transparent;
    border-color: #787878;
    color: #fff;

    &:hover,
    &:focus,
    &.loading {
        background-color: #787878;
        border-color: #787878;
    }
}
.button--brand {
    background-color: @main-color;
    border-color: @main-color;
    color: #fff;

    &:hover,
    &:focus,
    &.loading {
        background-color: @main-color-hover;
        border-color: @main-color-hover;
    }
}
.button--large {
    height: 4rem;
    line-height: 4rem;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    border-radius: 2rem;

    .button__icon {
        .hmargin(1.5rem);
    }
}
.button--narrow {
    padding-left: 2rem;
    padding-right: 2rem;
}
.button--wide {
    width: 100%;
}