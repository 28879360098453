/* Notifications */
.notifications {}
.notifications__item {
    .vmargin(.5rem);
    padding: 1rem 1.5rem;
    color: #fff;
}
.notifications__item--error {
    background-color: #dd4b39;
    border-color: #d73925;
}
.notifications__item--success {
    background-color: #00a65a;
    border-color: #008d4c;
}
.notifications__item--warning {
    background-color: #f39c12;
    border-color: #e08e0b;
}
.notifications__item--info {
    background-color: #00c0ef;
    border-color: #00acd6;
}