/* Files */
.files {}
.files__item {}
.file {
    .vmargin(5px);
    display: flex;
    align-content: stretch;
    background: #fafafa;
}
.file__title {
    flex-grow: 1;
    padding: 1.5rem 2rem;

    .responsive-max(543px, {
        font-size: 1.4rem;
    });
}
.file__download {
    .transition;
    flex: 0 0 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: @text-color;
    font-size: 1.8rem;
    text-align: center;
    border-left: 1px solid rgba(0,0,0,.05);

    &:hover {
        background: @main-color;
        color: #fff;
    }

    .responsive-max(543px, {
        flex-basis: 6rem;
    });
}