/* Authors item */
.authors-item {
    background-color: @alt-color;
    border-radius: 2px;
    padding: 3rem;

    /*.responsive-max(543px, {
        padding: 1.5rem;
    });*/
}
.authors-item__row {}
.authors-item__col {}
.authors-item__col--author {}
.authors-item__author {}
.authors-item__col--products {}
.authors-item__products {}