/* Tags */
.tags {
    line-height: 2rem;
}
.tags__item {
    .transition;
    display: inline-block;
    white-space: nowrap;

    &:hover {
        color: @main-color-hover;
    }
}
.tags__item-tick {
    position: absolute;
    left: -99999px;

    &:checked + .tags__item-label {
        color: @main-color;
    }

    &:focus + .tags__item-label {
        text-decoration: underline;
    }
}
.tags__item-label {
    cursor: pointer;
}
.tags__item--1 {
    font-size: 1.5rem;
}
.tags__item--2 {
    font-size: 1.4rem;
}
.tags__item--3 {
    font-size: 1.3rem;
}
.tags__item--4 {
    font-size: 1.2rem;
}
.tags__item--5 {
    font-size: 1.1rem;
}
.tags__item--6 {
    font-size: 1rem;
}