/* Popup */
.popup {
    padding: 3rem;
    width: 100%;
    //min-width: @min-width - 12px;
    max-width: 900px;
    margin: 6rem auto;
    position: relative;
    color: #fff;
    padding-top: 8rem;

    button.mfp-close {
        width: auto;
        height: auto;
        padding: 3rem;
        color: #fff;
    }

    .responsive-max(543px, {
        padding: 8rem 1.5rem 3rem;

        button.mfp-close {
            padding-right: 1.5rem;
            padding-left: 1.5rem;
        }
    });

}
.popup__errors {
    .vmargin(2rem);
}
.popup--inline {
    padding: 3rem;
    margin: 0 auto;
    background: @dark-color;
}
.popup--form {
    max-width: 500px;
}
.popup--wide {
    max-width: @max-width;
    padding-left: 0;
    padding-right: 0;
}
.popup__header {
    margin-bottom: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 2rem;
    font: @title-font;

    .responsive-max(991px, {
        font-size: 2.6rem;
    });
}
.popup__header--wrap {
    flex-wrap: wrap;

    .popup__title {
        white-space: nowrap;
    }
}
.popup__header--baseline {
    //align-items: baseline;
    justify-content: flex-start;

    .popup__title {
        margin-top: .4rem;
        border-bottom: 3px solid transparent;
    }
}
.popup__title {
    flex: 0 1 auto;
    margin-right: 1rem;
}
.popup__title--center {
    flex: 1;
    text-align: center;
    margin-right: 0;
    padding-left: 2rem;
}
.popup__categories {
    flex: 1 1 auto;
    position: relative;
}
.popup__categories--slider {

    .responsive-max(899px, {
        overflow: hidden;

        &::after {
            .absolute-block(100%,100%);
            z-index: 2;
            left: 0;
            top: 0;
            background-image: linear-gradient(to right, rgba(255,255,255,0) 70%, @dark-color 100%);
            pointer-events: none;
        }
    });

    &.categories .slick-list {
        margin-left: 0;
        margin-right: 0;
    }
}
.popup__footer {
    margin-top: 3rem;
}
.popup__footer--right {
    text-align: right;
}