/* Advantages */
.section--advantages {
    background-color: @alt-color;

    .responsive-max(543px, {
        padding-top: 5rem;
        padding-bottom: 5rem;
    });

    &.section--main {
        background-color: #fff;
    }
}
.section__inner--advantages {}
.advantages {}
.advantages__row {
    margin: -7rem -(7rem / 2) 7rem -(7rem / 2);

    &:last-child {
        margin-bottom: 0;
    }
}
.advantages__col {
    padding: 7rem (7rem / 2) 0 (7rem / 2);
}
.advantages__item {
    text-align: center;
    max-width: 230px;
    margin-left: auto;
    margin-right: auto;
}
.advantages__item--horizontal {

    .responsive-min(768px, {
        max-width: 360px;
        text-align: left;

        .advantages__item-image {
            margin-left: 0;
            margin-right: 0;
        }
    });
}
.advantages__item--left {
    text-align: left;
    max-width: 390px;

    .advantages__item-image {
        margin-left: 0;
        margin-right: 0;
    }
}
.advantages__item-row {
    align-items: center;
}
.advantages__item-col {}
.advantages__item-image {
    .vmargin(4rem);
    max-width: 160px;
    margin-left: auto;
    margin-right: auto;

    .responsive-max(543px, {
        .vmargin(2rem);
    });
}
.advantages__item-title {
    .vmargin(1.5rem);
    font: @title-font;
    font-size: 1.6rem;
}
.advantages__item-descr {
    .vmargin(1.5rem);
    font-size: 1.6rem;
}