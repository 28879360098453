/* Profile data */
.profile-data {
    padding: 2rem;
    background: @alt-color;
    border-radius: 3px;
}
.profile-data__section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.profile-data__balance {
    margin-right: 2rem;
    margin-top: 1rem;
}
.profile-data__balance-title {
    color: @light-color;
}
.profile-data__balance-amount {
    white-space: nowrap;
    font-size: 2rem;
}
.profile-data__payout {
    margin-top: 1rem;
    flex: 0 1 260px;
}
.profile-data__comment {
    .vmargin(1rem);
    color: @light-color;
}
.profile-data__content {

    table {
        width: 100%;
    }
}
.profile-data__status {
    font-weight: 500;
    text-align: right;
}
.profile-data__amount {
    text-align: right;
    white-space: nowrap;
}
.profile-data__pay-status {
    text-align: right;
}
.profile-data__methods {
    .vmargin(2rem);
}
.profile-data__method {
    .vmargin(2rem);
    display: flex;
    align-items: center;
}
.profile-data__method-title {
    flex: 0 0 120px;
}
.profile-data__method-tick {
    display: none;

    &:checked {

        & + .profile-data__method-label {
            font-weight: 500;
            color: @dark-color;
        }
    }
}
.profile-data__method-label {
    color: @light-color;
    cursor: pointer;

    &:hover {
        color: @main-color-hover;
    }
}
.profile-data__method-form {
    flex: 1 1 100%;
}
.profile-data__footer {
    .vmargin(2rem);
    text-align: right;
}
.profile-data__chat {}