/* Products masonry */
.section--author-products {
    background: #fff url(../img/bg-author-prints.jpg) no-repeat 50% 100%;
    padding-bottom: 45rem;

    .responsive-max(767px, {
        background-size: contain;
        padding-bottom: 25rem;
    });

    .responsive-max(543px, {
        padding-bottom: 20rem;
    });

    .responsive-max(400px, {
        padding-bottom: 15rem;
    });

    .section--author-page + & {
        padding-top: 0;
    }
}
.section__inner--author-products {}
.author-products__categories {
    .vmargin(2.8rem);
    position: relative;
    overflow: hidden;

    .responsive-max(767px, {
        font-size: 2.4rem;
        line-height: 1.7;

        &::after {
            .absolute-block(100%,100%);
            z-index: 2;
            left: 0;
            top: 0;
            background-image: linear-gradient(to right, #fff 0%, rgba(255,255,255,0) 30%, rgba(255,255,255,0) 70%, #fff 100%);
            pointer-events: none;
        }
    });
}
.author-products {
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    .responsive-max(850px, {
        margin-left: -1rem;
        margin-right: -1rem;
    });

    .responsive-max(530px, {
        margin-left: -.7rem;
        margin-right: -.7rem;
    });
}
.author-products__sizer,
.author-products__item {
    width: 25%;

    .responsive-max(850px, {
        width: 33%;
    });

    .responsive-max(530px, {
        width: 50%;
    });
}
.author-products__item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 3rem;

    .product::after {
        display: none;
    }

    .responsive-max(850px, {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 2rem;
    });

    .responsive-max(530px, {
        padding-left: .7rem;
        padding-right: .7rem;
        margin-bottom: 1.5rem;
    });
}
.author-products__footer {
    .vmargin(3rem);
    text-align: center;
}