/* Products slider */
.section--products-slider {
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.section__inner--products-slider {}
.products-slider {
    padding-left: 8.33333%;
    padding-right: 8.33333%;

    &.slick-initialized .slick-track {
        display: flex;
        align-items: center;
    }

    .slick-list {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }

    .slick-slide {
        .transition;
        opacity: 0;
    }

    .slick-active,
    .slick-current {
        opacity: 1;
    }
}
.products-slider__item {
    margin-left: 1.5rem;
    margin-right: 1.5rem;

    img {
        margin-left: auto;
        margin-right: auto;
    }
}
.products-slider__item-image {}