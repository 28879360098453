/* Info */
.info {}
.info__item {
    .vmargin(1.5rem);

    &.active {

        .info__item-title {

            &::before {
                transform: rotate(90deg) translate(2px,2px);
            }
        }
    }
}
.info__item-title {
    .transition;
    .vmargin(1.2rem);
    font-weight: 500;
    padding-left: 1.5rem;
    position: relative;
    cursor: pointer;

    &::before {
        .transition;
        .absolute-block(10px,10px);
        top: 50%;
        margin-top: -5px;
        left: 0;
        border: 5px solid transparent;
        border-left-color: @main-color;
    }

    &:hover {
        color: @main-color-hover;
    }
}
.info__item-content {
    .transition;
    padding-left: 1.5rem;
    display: none;
}