/* Authors */
.section--authors {
    background-color: #fff;
    padding-bottom: 9rem;

    .responsive-max(767px, {
        padding-top: 5rem;
        padding-bottom: 5rem;
    });
}
.section__inner--authors {}
.authors {
    position: relative;
}
.authors__item {
    .vmargin(1rem);
}
.authors__footer {
    .vmargin(4rem);
    text-align: center;
}