/* Colors */
.colors {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -3px;
}
.colors--center {
    justify-content: center;
}
.colors--large {

    .colors__item {
        width: 36px;
        height: 36px;
        margin: 4px;
    }
    .colors__item-label {
        border-width: 1px;
    }
    .colors__item-color {
        width: ~"calc(100% - 6px)";
        height: ~"calc(100% - 6px)";
        left: 3px;
        top: 3px;
    }
}
.colors__item {
    .transition;
    margin: 3px;
    width: 24px;
    height: 24px;
    position: relative;
    display: block;
    overflow: hidden;
}
.colors__item-tick {
    position: absolute;
    left: -99999px;

    &:checked + .colors__item-label {
        border-style: solid;
    }

    &:focus ~ .colors__item-color {
        animation: colors-focus 1s linear;
    }
}
@-webkit-keyframes colors-focus {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes colors-focus {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.colors__item-label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-width: 1px;
    border-radius: 50%;
    cursor: pointer;
}
.colors__item-color {
    .transition;
    position: absolute;
    display: block;
    width: ~"calc(100% - 4px)";
    height: ~"calc(100% - 4px)";
    left: 2px;
    top: 2px;
    border-radius: 50%;
    pointer-events: none;
}

.colors__item--black {

    .colors__item-color {
        background-color: #0d0d0d;
    }
}
.colors__item--monochrome {

    .colors__item-color {
        background-image: linear-gradient(to right, #000 0%, #000 49.999%, #fff 50%, #fff 100%);
    }
}
.colors__item--white {

    .colors__item-label {
        border-color: #0d0d0d;
    }

    .colors__item-color {
        background-color: #fff;
        border: 1px solid #e2e2e2;
    }
}
.colors__item--multicolor {

    .colors__item-label {
        border-color: #0d0d0d;
    }

    .colors__item-color {
        background-image: url(../img/multicolor.png);
        background-size: contain;
    }
}
.colors__item--natural {

    .colors__item-label {
        border-color: #0d0d0d;
    }

    .colors__item-color {
        background-image: url(../img/natural.png);
        background-size: contain;
    }
}
.colors__item--nocolor {

    .colors__item-label {
        border-color: #0d0d0d;
    }

    .colors__item-color {
        background-color: #fff;
        border: 1px solid #e2e2e2;
    }

    &::after {
        .absolute-block(120%,2px);
        left: 50%;
        top: 50%;
        background: @main-color;
        transform-origin: center center;
        transform:  translate(-50%,-50%) rotate(-45deg);
        pointer-events: none;
    }
}