/* Cart */
.section--cart {
    padding-bottom: 9rem;
}
.section__inner--cart {}
.cart {
    .vmargin(8rem);
}
.cart__row {}
.cart__col {}
.cart__col--list {}
.cart__list {}
.cart__item {
    .vmargin(1rem);
}
.cart__item--gift {}

.cart__col--total {}
.cart__total {
    .vmargin(5rem);
    padding: 2rem;
    background: @alt-color;
    border-radius: 2px;

    .responsive-max(543px, {
        padding: 1rem;
    });
}
.cart__total-title {
    .vmargin(2rem);
    font: @title-font;
    font-size: 2rem;
    font-weight: 300;
}
.cart__total-list {
    .vmargin(2rem);
    width: 100%;
}
.cart__total-row {
    td {
        padding-bottom: 3px;
        font-size: 1.2rem;
        vertical-align: bottom;
    }
}
.cart__total-name {}
.cart__total-count {
    text-align: right;
    white-space: nowrap;
}
td.cart__total-amount {
    text-align: right;
}
.cart__total-gift {
    color: @light-color;
    margin-left: 5px;
    height: 1.3rem;
}
.cart__total-sum {
    .vmargin(2rem);
    padding-top: 1.5rem;
    border-top: 1px solid @light-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
}
.cart__total-sum-title {
    margin-right: 1rem;
}
.cart__total-sum-amount {}
.cart__total-footer {
    .vmargin(2rem);
    text-align: center;
}

.cart__info {
    .vmargin(5rem);
}