/* Order */
.order {}
.order__row {}
.order__col {}
.order__section {}
.order__section-title {
    .vmargin(3rem);
    font: @title-font;
    font-size: 2rem;
    font-weight: 300;
}
.order__section-content {}
.order__form {}