/* Product info */
.section--product-info {
    padding-top: 7rem;
    padding-bottom: 6rem;

    .responsive-max(543px, {
        padding-top: 1.5rem;
    });
}
.section__inner--product-info {}
.product-info {
    background-color: @alt-color;
    border-radius: 2px;
    padding: 4rem 1.5rem;
}
.product-info--main {
    padding: 0;
    border-radius: 0;
    background-color: transparent;

    .product-info__row {
        margin: -7rem -(3rem / 2) 7rem -(3rem / 2);

        &:last-child {
            margin-bottom: 0;
        }

        .responsive-max(543px, {
            margin-top: 0;
        });
    }
    .product-info__col {
        padding: 7rem (3rem / 2) 0 (3rem / 2);
    }

    .product-info__col--author {

        .responsive-min(768px, {
            padding-left: 1.5rem;
        });
    }

    .product-info__author {
        max-width: none;
    }

    .product-info__col--content {

        .responsive-max(543px, {
            order: -1;
            padding-top: 0;
        });

        .responsive-min(768px, {
            padding-left: 1.5rem;
        });
    }

    .product-info__print {
        max-width: 375px;
        max-height: 500px;
    }
}
.product-info__close {
    .transition;
    .icon-close;
    display: block;
    position: absolute;
    z-index: 10;
    font-size: 0;
    line-height: 0;
    background: none;
    width: 40px;
    height: 40px;
    padding: 10px;
    right: 10px;
    top: 10px;
    color: @light-color;

    &::before {
        font-size: 20px;
    }

    &:hover {
        color: @text-color;
    }

    .ie & {
        top: 30px;
    }

    .responsive(544px, 767px, {
        top: 0;
        right: 0;

        .ie & {
            top: 20px;
        }
    });
}
.product-info__row {}
.product-info__col {}
.product-info__col--author {

    .responsive-min(768px, {
        padding-right: 0;
    });
}
.product-info__author {
    max-width: 153px;
    margin-left: auto;
    margin-right: auto;
}
.product-info__col--content {

    .responsive-min(768px, {
        padding-left: 0;
    });
}
.product-info__content {

    .responsive-max(543px, {
        max-width: 321px;
        margin-left: auto;
        margin-right: auto;
    });
}
.product-info__print {
    max-width: 321px;
    //max-height: 296px;
    margin-left: auto;
    margin-right: auto;
    display: block;

    .responsive(544px, 767px, {
        margin-left: 0;
        margin-right: 0;
    });
}
.product-info__col--about {}
.product-info__about {}
.product-info__title {
    .vmargin(2.5rem);
    font: @title-font;
    font-size: 2rem;
}
.product-info__descr {
    .vmargin(2.5rem);
}
.product-info__params {
    .vmargin(3.5rem);
}
.product-info__params-row {}
.product-info__params-col {}
.product-info__param {
    .vmargin(2.5rem);
}
.product-info__param-header {
    .vmargin(1.5rem);
    font: @title-font;
    font-size: 1.3rem;
}
.product-info__param-title {
    display: inline-block;
    color: @dark-color;
}
.product-info__param-info {
    .transition;
    margin-left: 3px;
    display: inline-block;
    color: @light-color;

    a&:hover {
        color: @main-color-hover;
    }
}
.product-info__param-content {}
.product-info__sizes {}
.product-info__frame-colors {}
.product-info__suspension-colors {}
.product-info__price {
    .vmargin(2.5rem);
    font: @title-font;
    font-size: 3rem;
}
.product-info__footer {
    .vmargin(2.5rem);
    margin: -.5rem -1.5rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    display: flex;
}
.product-info__button {
    margin: .5rem 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    flex: 0 1 125px;
}