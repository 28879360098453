/* Profile */
.section--profile {

    & + .section--author-products {
        padding-top: 3rem;
    }
}
.section__inner--profile {}

.profile-container {}
.profile-container__row {}
.profile-container__col {}

.profile {}
.profile__row {}
.profile__col {}
.profile__image {
    .icon-close;
    .vmargin(2rem);
    width: 90px;
    height: 90px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    overflow: hidden;
    position: relative;

    img {
        width: 100%;
        position: relative;
    }

    &::before {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background: @alt-color;
        left: 0;
        top: 0;
        line-height: 90px;
        font-size: 20px;
        color: @light-color;
        text-align: center;
    }

    input[type=file] {
        width: 100%;
    }
}
.popup__errors {
    .vmargin(2rem);
}
.profile__data {
    border: 1px solid transparent;
}
.profile__data--center {
    text-align: center;
}
.profile__data--text {}
.profile__input {
    .transition;
    .vmargin(1px);
    display: block;
    display: none;
    width: 100%;
    border: none;
    border-bottom: 1px solid @light-color;
    //border-radius: 3px;
    padding: 0;

    &:hover,
    &:active {
        border-color: @main-color-hover;
    }

    &:focus {
        outline: none;
        border-color: @dark-color;
    }
}
.profile__input--text {
    height: 120px;
    resize: vertical;
}
.profile__input-errors {
    white-space: normal;
}
.profile__name {
    .vmargin(.5rem);
    font-weight: 500;
}
.profile__address {
    .vmargin(.5rem);
}
.profile__contacts {
    .vmargin(2rem);
}
.profile__contact {
    .vmargin(.8rem);
    white-space: nowrap;
}
.profile__contact-icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.3rem;
    line-height: .7rem;
    margin-right: 1.4rem;
    color: @light-color;

    .ie & {
        height: 1.3rem;
        line-height: 1.9rem;
    }
}
.profile__contact-value {
    display: inline-block;
    vertical-align: middle;
    max-width: ~"calc(100% - 3rem)";

    .profile__data {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.profile__section {
    .vmargin(3rem);
}
.profile__section--avatar {
    display: none;
}
.profile__section-title {
    .vmargin(1rem);
    color: @light-color;
}
.profile__share {
    display: flex;
    flex-wrap: wrap;
    margin: -1rem -1.5rem;

    .responsive(544px, 991px, {
        margin: -.5rem;
    });
}
.profile__share-button {
    .transition;
    flex: 0 1 ~"calc(50% - 3rem)";
    margin: 1rem 1.5rem;
    border-radius: 3px;
    height: 3rem;
    line-height: 3rem;
    padding: 0;
    color: #fff;
    background-color: @main-color;

    .ie & {
        flex: 1 1 auto;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &:hover,
    &:focus {
        background-color: @main-color-hover;
    }

    .responsive(544px, 991px, {
        flex: 0 1 ~"calc(50% - 1rem)";
        margin: .5rem;
    });
}
.profile__share-button--vkontakte {
    background-color: #507299;

    &:hover,
    &:focus {
        background-color: darken(#507299, 7%);
    }
}
.profile__share-button--facebook {
    background-color: #3b5998;

    &:hover,
    &:focus {
        background-color: darken(#3b5998, 7%);
    }
}
.profile__share-button--twitter {
    background-color: #1dcaff;

    &:hover,
    &:focus {
        background-color: darken(#1dcaff, 7%);
    }
}
.profile__share-button--telegram {
    background-color: #0088cc;

    &:hover,
    &:focus {
        background-color: darken(#0088cc, 7%);
    }
}
.profile__share-button--livejournal {
    background-color: #004359;

    &:hover,
    &:focus {
        background-color: darken(#004359, 7%);
    }
}
.profile__share-button--odnoklassniki {
    background-color: #f2720c;

    &:hover,
    &:focus {
        background-color: darken(#f2720c, 7%);
    }
}
.profile__share-button--google-plus {
    background-color: #db4437;

    &:hover,
    &:focus {
        background-color: darken(#db4437, 7%);
    }
}
.profile__about {}
.profile__footer {
    text-align: right;
}

.profile__subscriptions {}
.profile__subscriptions-item {
    .transition;
    display: block;

    &:hover {
        color: @main-color;
    }
}