/* Tabs */
.tabs {
    .vmargin(1rem);
    margin-left: -6px;
    margin-right: -6px;
    font-size: 2rem;
    display: flex;
    flex-wrap: wrap;

    .responsive(768px, 991px {
        font-size: 1.8rem;
    });

    .responsive-max(543px, {
        font-size: 1.6rem;
    });
}
.tabs__item {
    .transition;
    display: inline-block;
    margin: 4px 6px;
    margin-right: 6px;
    margin-bottom: 4px;
    border-bottom: 3px solid transparent;
    background: none;
    padding: 0;
    color: @light-color;

    &:hover {
        color: @main-color-hover;
    }
    &.current {
        color: @text-color;
        border-color: #0d0d0d;

        &:hover {
            color: @main-color-hover;
            border-color: @main-color-hover;
        }
    }

    &:last-child {
        margin-right: 0;
    }
}
.tabs-holder {}
.tabs-holder__item {
    display: none;
}