/* Social */
.social {
    margin: -5px -2px;
}
.social__item {
    .transition;
    display: inline-block;
    vertical-align: middle;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    line-height: 28px;
    text-align: center;
    margin: 5px 2px;
    background-color: @light-color;
    color: #fff;
    font-size: 1.6rem;

    &::before {
        line-height: 28px;
    }

    .ie & {
        line-height: 33px;
    }

    &:hover {
        background-color: @main-color-hover;
    }

    &.icon-vkontakte {

        &:hover {
            background-color: #507299;
        }
    }

    &.icon-facebook {

        &:hover {
            background-color: #3b5998;
        }
    }

    &.icon-twitter {

        &:hover {
            background-color: #1dcaff;
        }
    }

    &.icon-odnoklassniki {

        &:hover {
            background-color: #f2720c;
        }
    }

    &.icon-google-plus {

        &:hover {
            background-color: #db4437;
        }
    }
}
.social--icons {

    .social__item {
        background: none;

        &:hover {
            background: none;
            color: @main-color-hover;
        }

        &.icon-vkontakte {

            &:hover {
                color: #507299;
            }
        }

        &.icon-facebook {

            &:hover {
                color: #3b5998;
            }
        }

        &.icon-twitter {

            &:hover {
                color: #1dcaff;
            }
        }

        &.icon-odnoklassniki {

            &:hover {
                color: #f2720c;
            }
        }

        &.icon-google-plus {

            &:hover {
                color: #db4437;
            }
        }
    }
}
.social--large {
    margin: -5px -10px;

    .social__item {
        font-size: 2.8rem;
        margin: 5px 10px;
    }
}