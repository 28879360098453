/* Forms */
.form {}
.form--center {
    text-align: center;

    .form__input {
        text-align: center;
    }
}
.form__title {
    .vmargin(2rem);
    font: @title-font;
    font-size: 1.8rem;
}
.form__errors {
    .vmargin(2rem);
}
.form__row {

    .responsive-max(543px, {
        margin: -2rem -(2rem / 2) 2rem -(2rem / 2);

        &:last-child {
            margin-bottom: 0;
        }
    });
}
.form__col {

    .responsive-max(543px, {
        padding: 2rem (2rem / 2) 0 (2rem / 2);
    });
}
.form__input,
.form__file-value,
.form__check-label {
    .placeholder-color(darken(@light-color, 35%));
    .transition;
    background-color: @alt-color;
    border: 1px solid transparent;
    border-radius: 1.5rem;
    padding: .45rem 1.5rem;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;

    &::-ms-expand,
    &::-ms-reveal,
    &::-ms-clear {
        display: none;
    }

    &:hover {
        border-color: @main-color-hover;
    }
    &:active,
    &:focus {
        outline: none;
    }
}
.form__input-errors {
    .vmargin(1rem);
}
.form__file {
    .vmargin(5px);
    display: block;
    position: relative;
    overflow: hidden;
    padding: 0;
    border: none;
    width: 100%;
    border-radius: 0;
    background: none;

    input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        letter-spacing: 10em;     /* IE 9 fix */
        -ms-transform: scale(20); /* IE 9 fix */
        transform: scale(20);
        opacity: 0;
        cursor: pointer;
    }

    &:hover {

        .form__file-value {
            border-color: @main-color-hover;
            background-color: transparent;
        }
    }
}
.form__file-button {
    display: none;
}
.form__file-value {
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.form__file-value--selected {

}




.form__input--text {
    resize: none;
    height: 100px;
    overflow: hidden;
}
.form__input--select {
    background-image: url(../icons/select.svg);
    background-repeat: no-repeat;
    background-position: right 2rem top 50%;
    padding-right: 5.2rem;
    color: darken(@light-color, 35%);

    option,
    &.filled {
        color: @text-color;
    }

    .ie & {
        background-image: none;
    }
}

.form__input--large,
.form__file--large .form__file-value {
    border-radius: 2rem;
    padding: .85rem 3.5rem;
}
.form__input--center {
    text-align: center;
}
.form__button {}
.form__comment {
    .vmargin(2.5rem);
    color: #777;
}
.form__social {
    text-align: center;
}

.form__password {
    position: relative;

    .form__input {
        padding-right: 5rem;
    }
}
.form--center .form__password .form__input,
.form__password .form__input--center {
    padding-left: 5rem;
}
.form__password-tick {
    display: none;

    &:checked + .form__password-label {

        &::after {
            opacity: 1;
        }
    }
}
.form__password-label {
    .transition;
    .icon-eye;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -1.5rem;
    height: 3rem;
    line-height: 3rem;
    width: 5rem;
    text-align: center;
    font-size: 1.6rem;
    cursor: pointer;

    &::before {
        line-height: 3rem;
    }

    &::after {
        .transition;
        .absolute-block(2rem,1px);
        left: 50%;
        margin-left: -1rem;
        top: 50%;
        transform-origin: center center;
        transform: rotate(-45deg);
        background: @dark-color;
        opacity: 0;
    }

    &:hover {
        color: @main-color-hover;

        &::after {
            background: @main-color-hover;
        }
    }
}
.form__advantages {
    font-size: 1.8rem;
    text-align: left;

    p {
        margin-top: 0;
    }

    ul {
        li {
            margin-bottom: 2.4rem;
            padding-left: 1.1em;

            &::before {
                display: inline-block;
                margin-left: -1.1em;
                margin-right: .5rem;
                content: "—";
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .responsive-max(767px, {
        font-size: 1.6rem;

        ul li {
            margin-bottom: 2rem;
        }
    });

    .responsive-max(543px, {
        font-size: 1.3rem;

        ul li {
            margin-bottom: 1rem;
        }
    });
}
.form__advantages-image {
    width: 100%;
    text-align: right;

    img {
        border-radius: .3rem;
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        max-width: 390px;
    }

    .responsive-max(543px, {
        text-align: left;
    });
}
.form__advantages-title {
    font: @title-font;
    font-size: 2.2rem;
}
.form--search {
    .icon(search, after);
    flex: 1 1 170px;
    max-width: 200px;
    position: relative;

    .ie & {
        display: inline-block;
    }

    &::after {
        color: @light-color;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 3rem;
        height: 3rem;
        line-height: 3rem;
        pointer-events: none;
    }

    .form__input {
        padding-right: 4rem;
    }

    // omg...
    .ie & {

        &::after {
            top: 65px;
        }
    }
}
.form--subscribe {}
.form--login {
    max-width: 210px;
    margin-left: auto;
    margin-right: auto;

    .form__button {
        width: 100%;
    }
}
.form--register {
    margin-left: auto;
    margin-right: auto;

    .form__button {
        width: 100%;
    }
}
.form--popup {
    .form__input,
    .form__file-value,
    .form__check-label {
        .placeholder-color(@light-color);
    }

    .form__input {
        .vmargin(2rem);
        display: block;
        background-color: transparent;
        border: 2px solid #787878;
        color: #fff;

        &:hover {
            border-color: @main-color-hover;
        }
        &:active,
        &:focus {
            border-color: #fff;
            outline: none;
        }
    }

    .form__input--select {
        color: darken(@light-color, 25%);

        option {
            color: @text-color;
        }

        &.filled {
            color: #fff;

            option {
                color: @text-color;
            }
        }
    }

    .form__file-value {
        border: 2px solid #787878;
    }

    .form__password-label {
        color: #787878;

        &::after {
            background: #787878;
        }

        &:hover {
            color: @main-color-hover;

            &::after {
                background: @main-color-hover;
            }
        }
    }

    .form__file-value {
        background: #787878;
    }
}
.form--profile-data {

    .form__input {
        border-color: #e2e2e2;
        display: block;
        width: 100%;

        &:hover {
            border-color: @main-color-hover;
        }
        &:active,
        &:focus {
            border-color: @dark-color;
            outline: none;
        }
    }

    .form__row {
        margin: -1rem -(1rem / 2) 1rem -(1rem / 2);

        &:last-child {
            margin-bottom: 0;
        }
    }

    .form__col {
        padding: 1rem (1rem / 2) 0 (1rem / 2);
    }
}
.form--product {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}
.form--feedback {

    .form__input {
        .vmargin(1.5rem);
    }
}

.form__check {}
.form__check-tick {
    position: absolute;
    left: -9999px;

    &:checked + .form__check-label {
        border-color: @dark-color;
    }
}
.form__check-label {
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;
    border-color: #e2e2e2;
    cursor: pointer;
}
.form__check-name {
    flex: 1;
    margin-right: 5px;
}
.form__check-price {}

.form--order {

    .form__row {
        margin: -2rem -(1rem / 2) 2rem -(1rem / 2);

        &:last-child {
            margin-bottom: 0;
        }
    }

    .form__col {
        padding: 2rem (1rem / 2) 0 (1rem / 2);
    }

    .form__input {
        .vmargin(2rem);
    }

    .form__check {
        .vmargin(2rem);
    }
}
.form--advantages {

    .form__row {
        margin: -4rem -(3rem / 2) 4rem -(3rem / 2);

        &:last-child {
            margin-bottom: 0;
        }
    }

    .form__col {
        padding: 4rem (3rem / 2) 0 (3rem / 2);
    }
}