@font-face {
    font-family: "MuseoSans";
    src: url("../fonts/MuseoSansCyrl-300.eot");
    src: url("../fonts/MuseoSansCyrl-300.eot?#iefix") format("embedded-opentype"),
         url("../fonts/MuseoSansCyrl-300.woff2") format("woff2"),
         url("../fonts/MuseoSansCyrl-300.woff") format("woff"),
         url("../fonts/MuseoSansCyrl-300.ttf") format("ttf"),
         url("../fonts/MuseoSansCyrl-300.svg#MuseoSans") format("svg");
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: "MuseoSans";
    src: url("../fonts/MuseoSansCyrl-500.eot");
    src: url("../fonts/MuseoSansCyrl-500.eot?#iefix") format("embedded-opentype"),
         url("../fonts/MuseoSansCyrl-500.woff2") format("woff2"),
         url("../fonts/MuseoSansCyrl-500.woff") format("woff"),
         url("../fonts/MuseoSansCyrl-500.ttf") format("ttf"),
         url("../fonts/MuseoSansCyrl-500.svg#MuseoSans") format("svg");
    font-style: normal;
    font-weight: 500;
}