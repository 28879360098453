/* Chat */
.chat {}
.chat__list {
    display: flex;
    flex-direction: column;
    height: 220px;
    overflow: auto;

    .ie & {
        display: block;
    }
}
.chat__item {
    .vmargin(1rem);
    flex: 0 0 auto;
    padding: 1rem;
    background-color: #fff;
    max-width: 90%;

    .ie & {
        max-width: 100%;
    }
}
.chat__item-title {
    font-weight: 500;
    margin-bottom: .5rem;
}
.chat__item--my {
    align-self: flex-end;
}
.chat__form {
    .vmargin(2rem);
}