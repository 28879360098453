/* Cart item */
.cart-item {
    padding: 2rem;
    position: relative;
    background: @alt-color;
    border-radius: 2px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .responsive-max(543px, {
        padding: 1rem;
    });

    /*.responsive(768px, 991px, {
        display: block;
    });*/
}
.cart-item--gift {
    /*display: block;

    .cart-item__content {
        padding-left: 0;
    }*/
}
.cart-item__delete {
    .transition;
    .icon-close;
    display: block;
    position: absolute;
    z-index: 10;
    font-size: 0;
    line-height: 0;
    background: none;
    width: 40px;
    height: 40px;
    padding: 10px;
    right: 10px;
    top: 10px;
    color: @light-color;

    &::before {
        font-size: 20px;
    }

    &:hover {
        color: @main-color-hover;
    }

    .ie & {
        top: 30px;
    }

    .responsive-max(767px, {
        top: 0;
        right: 0;

        .ie & {
            top: 20px;
        }
    });
}
.cart-item__print {
    flex: 0 0 40%;
    max-width: 130px;

    .responsive-max(543px, {
        flex-basis: 35%;
    });
}
.cart-item__content {
    flex: 1 1 auto;
    padding-left: 3rem;

    .responsive-max(543px, {
        padding-left: 2rem;
    });
/*
    .responsive(768px, 991px, {
        padding-left: 0;
        padding-top: 3rem;
    });*/
}
.cart-item__title {
    .vmargin(.3rem);
    font: @title-font;
    font-size: 1.3rem;
    font-weight: 500;
    padding-right: 2rem;
}
.cart-item__author {
    .vmargin(.3rem);
    padding-right: 2rem;
}
.cart-item__params {
    .vmargin(1.7rem);
}
.cart-item__descr {
    .vmargin(1.7rem);
}
.cart-item__descr--short {
    max-height: 3em;
    overflow: hidden;
    position: relative;

    &::after {
        .absolute-block(100%,1.5em);
        .hgradient(rgba(255,255,255,0), @alt-color);
        bottom: 0;
        right: 0;
    }
}
.cart-item__footer {
    .vmargin(1.7rem);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: -5px;
}
.cart-item__count {
    margin: 5px;
    background: #fff;
    display: flex;
    width: 90px;
    border-radius: 15px;
    overflow: hidden;
}
.cart-item__count .bootstrap-touchspin {
    display: flex;
    width: 100%;
}
.cart-item__count-button,
.cart-item__count .input-group-btn {
    .transition;
    padding: 0;
    background: none;
    flex: 0 0 28px;
    line-height: 34px;
    height: 30px;
    text-align: center;
    color: @main-color;

    &:hover {
        color: @dark-color;
    }
}
.cart-item__count .input-group-btn .cart-item__count-button {
    height: auto;
}
.cart-item__count-value {
    width: 34px;
    border: none;
    text-align: center;
    outline: none;
    height: 30px;
    line-height: 30px;
    padding: 0;
}
.cart-item__amount {
    flex: 1;
    white-space: nowrap;
    margin: 5px;
    margin-left: 2rem;

    strong {
        font-weight: 300;
    }

    .responsive-max(543px, {
        font-size: 1.6rem;
        font-weight: 500;

        strong {
            font-weight: 500;
        }
    });
}
.cart-item__gift-button {
    margin: 5px;
}