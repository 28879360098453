/* Promo */
.section--promo {
    background: #fff url(../img/bg-collector-prints.jpg) no-repeat center bottom -370px;
    min-height: 670px;
    position: relative;
    padding-bottom: 14rem;

    &::after {
        .absolute-block(100%,30px);
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to top, rgba(119, 119, 119, 0.15) 0%, rgba(119, 119, 119, 0) 100%);
    }

    .responsive-max(991px, {
        padding-bottom: 24rem;
        background-position: center bottom -270px;
    });

    .responsive-max(767px, {
        padding-top: 5rem;
        padding-bottom: 44rem;
        background-position: center bottom -70px;
    });
}
.section__inner--promo {}
.promo {
    text-align: center;

    .responsive-max(543px, {
        padding-bottom: 3rem;
    });
}
.promo__title {
    .vmargin(4rem);
    position: relative;
    z-index: 1;
    font: @title-font;
    font-size: 3rem;

    .responsive-max(543px, {
        .vmargin(2rem);
        font-size: 2.6rem;
    });
}
.promo__descr {
    .vmargin(4rem);
    position: relative;
    z-index: 1;
    font-size: 2rem;
    line-height: 1.5;

    .responsive-max(543px, {
        .vmargin(2rem);
        font-size: 1.6rem;
    });
}
.promo__footer {
    position: relative;
    z-index: 1;
}
.promo__collection {
    margin-top: -130px;
    z-index: 0;

    .responsive-max(583px, {
        margin-top: 5rem;

        .collection__print--7 {
            display: block;
            width: 51%;
            right: 0;
            top: 0;
            left: auto;
        }
    });

    .responsive(387px, 583px, {
        width: 356px;
        height: 357px;

        .collection__print--7 {
            width: auto;
        }
    });

    .responsive(584px, 787px, {
        width: 544px;
    });
}