/* Footer */
.section--footer {
    padding-top: 0;
    padding-bottom: 0;
    background: @dark-color;
    color: #fff;
    flex-shrink: 0;
    padding-top: 7rem;
    padding-bottom: 4rem;
}
.section__inner--footer {}
.footer {}
.footer__row {}
.footer__col {}
.footer__section {}
.footer__section-title {
    .vmargin(1.5rem);
}
.footer__section-content {
    .vmargin(1.5rem);
    color: @light-color;
}
.footer__menu {
    .vmargin(2.5rem);

    .responsive-max(767px, {
        display: none;
    });
}
.footer__copyright {
    .vmargin(2.5rem);
}
.footer__nav {}
.footer__nav-item {
    .transition;
    display: block;
    padding: .7rem 0;
    border-bottom: 1px solid rgba(255,255,255,.05);

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        color: @main-color-hover;
    }
}
.footer__nav-item--current {
    color: #fff;
}