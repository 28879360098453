/* Utils */
.gradient(@start: #eee, @stop: #fff, @direction: to bottom) {
    background: mix(@start, @stop);
    background: linear-gradient(@direction, @start 0%, @stop 100%);
}
.vgradient(@start: #eeeeee, @stop: #ffffff) {
    background: mix(@start, @stop);
    background: linear-gradient(to bottom, @start 0%, @stop 100%);
}
.hgradient(@start: #eeeeee, @stop: #ffffff) {
    background: mix(@start, @stop);
    background: linear-gradient(to right, @start 0%, @stop 100%);
}
.absolute-block(@width: auto, @height: auto) {
    content: "";
    position: absolute;
    display: block;
    width: @width;
    height: @height;
}
.transition(@arguments: all 0.3s ease-out) {
    transition: @arguments;
}
.user-select(@select: none) {
    -webkit-touch-callout: @select;
    -webkit-user-select: @select;
    -moz-user-select: @select;
    -ms-user-select: @select;
    user-select: @select;
}
.vmargin(@margin: 1em) {
    margin-top: @margin;
    margin-bottom: @margin;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}
.hmargin(@margin: 1em) {
    margin-left: @margin;
    margin-right: @margin;

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}
.columns(@colcount: 2, @colgap: 3rem, @colwidth: auto, @columnRuleColor: transparent, @columnRuleStyle: solid, @columnRuleWidth: 0) {
    -moz-column-width: @colwidth;
    -moz-column-count: @colcount;
    -moz-column-gap: @colgap;
    -moz-column-rule-color: @columnRuleColor;
    -moz-column-rule-style: @columnRuleStyle;
    -moz-column-rule-width: @columnRuleWidth;
    -webkit-perspective: 1;
    -webkit-column-width: @colwidth;
    -webkit-column-count: @colcount;
    -webkit-column-gap: @colgap;
    -webkit-column-rule-color: @columnRuleColor;
    -webkit-column-rule-style: @columnRuleStyle;
    -webkit-column-rule-width: @columnRuleWidth;
    column-width: @colwidth;
    column-count: @colcount;
    column-gap: @colgap;
    column-rule-color: @columnRuleColor;
    column-rule-style: @columnRuleStyle;
    column-rule-width: @columnRuleWidth;
}
.placeholder-color(@color: #777777) {
    &::-webkit-input-placeholder {
        color: @color;
    }
    &:-moz-placeholder {
        color: @color;
    }
    &::-moz-placeholder {
        color: @color;
    }
}
.background-rgba(@color, @opacity: .5) {
    background-color: rgba(red(@color), green(@color), blue(@color), @opacity);
}
.responsive-min(@minWidth; @rules) {
    @media only screen and (min-width: @minWidth) {
        @rules();
    }
}
.responsive-max(@maxWidth; @rules) {
    @media only screen and (max-width: @maxWidth) {
        @rules();
    }
}
.responsive(@minWidth; @maxWidth; @rules) {
    @media only screen and (min-width: @minWidth) and (max-width: @maxWidth) {
        @rules();
    }
}