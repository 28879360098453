/* Technics */
.technics {
    display: flex;
    flex-wrap: wrap;
}
.technics__item {
    .transition;
    flex: 1 0 48%;
    white-space: nowrap;

    &:hover {
        color: @main-color-hover;
    }
}
.technics__item-tick {
    position: absolute;
    left: -99999px;

    &:checked + .technics__item-label {
        color: @main-color;
    }

    &:focus + .technics__item-label {
        text-decoration: underline;
    }
}
.technics__item-label {
    cursor: pointer;
}