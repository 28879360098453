/* Header */
.section--header {
    background-color: @dark-color;
    color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    flex-shrink: 0;
}
.mfp-ready ~ .page {
    padding-top: 60px;
    /*
    .section--header {
        z-index: 2000;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        margin-bottom: 0;
        padding-right: 15px;
    }
    */
    .section--fixed-menu + .sections {
        padding-top: 50px;
    }
}
.section__inner--header {}
.header {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header__logo {
    display: flex;
    align-items: center;

    .responsive-max(359px, {
        display: block;
    });
}
.header__logo-image {
    height: 14px;
}
.header__logo-motto {
    border-left: 1px solid #fff;
    padding-left: 1.2rem;
    margin-left: 1.2rem;
    font-size: 1.6rem;
    line-height: 1;
    padding-top: 3px;
    padding-bottom: 3px;

    .responsive-max(359px, {
        display: block;
        padding: 0;
        margin-left: 0;
        border-left: none;
        margin-top: 7px;
        font-size: 1.7rem;
    });
}
.header__menu {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .responsive-max(767px, {
        display: none;
    });
}
.header__menu-item {
    .hmargin(1rem);
    display: flex;
    align-items: center;
}
.header__menu-links {}
.header__link {
    .transition;
    .hmargin(1rem);
    color: #fff;

    &:hover {
        color: @main-color;
    }
}
.header__menu-buttons {
    .hmargin(3rem);
}
.header__button {
    .hmargin(.5rem);
    min-width: 125px;
}