.has(@element; @content){
  &__@{element}{
    @content();
  }
}

.variant(@modifier; @content){
  &--@{modifier} {
    @content();
  }
}