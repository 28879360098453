/* Print */
.print {
    display: block;
    position: relative;
    padding: 7px;

    &.print--clocks {
        padding: 7px;
    }
}
.print__image {
    position: relative;
    z-index: 0;
    box-shadow: 0 0 8px 0 rgba(0,0,0,.15);

    img {
        width: 100%;
        position: relative;
        z-index: 1;
    }
}
.print__gift {
    overflow: hidden;
    position: absolute;
    left: -3px;
    top: -3px;
    width: ~"calc(100% + 6px)";
    height: ~"calc(100% + 6px)";
    z-index: 10;

    &::before {
        .absolute-block(6px,100%);
        left: 50%;
        top: 0;
        margin-left: -3px;
        background: #45718c;
        z-index: 2;
    }

    &::after {
        .absolute-block(100%,6px);
        left: 0;
        top: 50%;
        margin-top: -3px;
        background: #45718c;
        z-index: 2;
    }
}
.print__gift-pack {
    position: absolute;
    left: 100%;
    top: 15%;
    width: 200%;
    height: 200%;
    background: #ecf1f7;
    transform-origin: left top;
    transform: rotate(70deg);
    z-index: 1;
}
.print__gift-ribbon {

    &::before,
    &::after {
        .absolute-block(26px,6px);
        left: 50%;
        top: 50%;
        margin-left: -13px;
        margin-top: -3px;
        background: #45718c;
        z-index: 3;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(135deg);
    }
}
.print--frame,
.print--clocks {
    box-shadow: 0 0 5px rgba(0,0,0,.1);

    &::before {
        .absolute-block(100%,100%);
        z-index: 1;
        left: 0;
        top: 0;
        border: 7px solid transparent;
        box-shadow: inset 0 0 5px rgba(0,0,0,.3);
        border-radius: 2px;
    }
}
.print--clocks {
    border-radius: 50%;
    overflow: hidden;

    &::before {
        border-radius: 50%;
    }

    .print__image {
        border-radius: 50%;
        overflow: hidden;
    }
}
.print__clock-arrows {
    left: 50%;
    bottom: 42%;
    width: 1px;
    background: #fff;
    position: absolute;
    display: block;
    height: 45%;
    z-index: 1;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.6);

    &::before,
    &::after {
        position: absolute;
        display: block;
        content: "";
        left: 50%;
        bottom: 0;
        background: #fff;
        box-shadow: 0 0 5px 0 rgba(0,0,0,.6);
    }

    &::before {
        width: 4px;
        height: 65%;
        transform: rotate(-60deg);
        transform-origin: 50% 75%;
    }

    &::after {
        width: 2px;
        height: 85%;
        transform: rotate(50deg);
        transform-origin: 50% 78%;
    }
}
.print--suspension {
    box-shadow: 0 5px 5px -5px rgba(0,0,0,.1), 0 -5px 5px -5px rgba(0,0,0,.1);

    &::before {
        .absolute-block(100%,100%);
        z-index: 1;
        left: 0;
        top: 0;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        box-shadow: inset 0 5px 5px -5px rgba(0,0,0,.3), inset 0 -5px 5px -5px rgba(0,0,0,.3);
        border-radius: 2px;
    }

    .print__image {

        &::before,
        &::after {
            .absolute-block(7px,15px);
            top: -12px;
            border: 2px solid #0d0d0d;
            border-radius: 50%;
            box-shadow: 0 0 5px rgba(0,0,0,.2);
        }

        &::before {
            left: 15%;
        }

        &::after {
            right: 15%;
        }
    }
}
.print--canvas {

    .print__image {
        box-shadow: 0 0 12px 0 rgba(0,0,0,.25);

        &::after {
            .absolute-block(100%,100%);
            z-index: 1;
            left: 0;
            top: 0;
            background: url(../img/canvas.png);
            background-size: 100%;
            opacity: .15;
        }
    }
}

.print--large {
    padding: 10px;

    &::before {
        border-width: 10px;
    }

    &.print--canvas {
        padding: 0;
    }

    &.print--suspension {

        .print__image {

            &::before,
            &::after {
                width: 8px;
                height: 16px;
                top: -16px;
                border-width: 2.5px;
            }
        }
    }

    &.print--clocks {
        padding: 10px;
    }

    .print__clock-arrows {
        width: 2px;

        &::before {
            width: 8px;
        }

        &::after {
            width: 4px;
        }
    }
}

.print--main {
    padding: 15px;

    &::before {
        border-width: 15px;
    }

    &.print--canvas {
        padding: 0;
    }

    &.print--suspension {

        .print__image {

            &::before,
            &::after {
                width: 10px;
                height: 20px;
                top: -22px;
                border-width: 3px;
            }
        }
    }

    &.print--clocks {
        padding: 15px;
    }

    .print__clock-arrows {
        width: 2px;

        &::before {
            width: 8px;
        }

        &::after {
            width: 4px;
        }
    }
}
.print--frame-black {

    &::before {
        border-color: #0d0d0d;
    }

    &.print--suspension {

        .print__image {

            &::before,
            &::after {
                border-color: #0d0d0d;
            }
        }
    }
}
.print--arrows-black {

    .print__clock-arrows {
        background: #0d0d0d;

        &::before,
        &::after {
            background: #0d0d0d;
        }
    }
}
.print--arrows-white {

    .print__clock-arrows {
        background: #fff;

        &::before,
        &::after {
            background: #fff;
        }
    }
}
.print--frame-white {

    &::before {
        border-color: #fff;
    }

    &.print--suspension {

        .print__image {

            &::before,
            &::after {
                border-color: #fff;
            }
        }
    }
}
.print--frame-natural {

    &::before {
        border-color: #e0ccac;
        border-image: url(../img/wood.png) 200 stretch;
    }

    &.print--suspension {

        .print__image {

            &::before,
            &::after {
                border-color: #e0ccac;
            }
        }
    }
}
.print[data-recommend] {
    overflow: hidden;

    &::after {
        .background-rgba(@main-color, .9);
        content: attr(data-recommend);
        display: block;
        position: absolute;
        z-index: 3;
        padding: .5rem;
        left: -15px;
        right: -15px;
        bottom: 20%;
        font-size: 1.3rem;
        color: #fff;
        text-transform: uppercase;
        font-weight: 500;
        transform-origin: center center;
        transform: rotate(-12deg);
        text-align: center;
    }
}