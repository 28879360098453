/* About */
.section--about {
    background: @alt-color;
    padding-top: 5rem;
    padding-bottom: 0;
}
.section__inner--about {}
.about {
    .transition(all 1s ease);
    .icon(next, after);
    max-height: 100px;
    overflow: hidden;
    position: relative;
    padding-bottom: 5rem;

    &::before {
        .transition(all 1s ease);
        .absolute-block(100%,100%);
        background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, @alt-color 70%, @alt-color 100%);
        bottom: 0;
        left: 0;
        cursor: pointer;
    }

    &::after {
        .transition(all 1s ease);
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        left: 50%;
        margin-left: -10px;
        bottom: 10px;
        font-size: 20px;
        transform: rotate(90deg);
        cursor: pointer;
        transform-origin: center center;
    }

    &.active {
        max-height: 3000px;

        &::before {
            height: 5rem;
        }
        &::after {
            bottom: 20px;
            transform: rotate(-90deg);
        }

    }

    &:hover {

        &::after {
            color: @main-color-hover;
        }
    }
}