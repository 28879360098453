/* Contacts */
.section--contacts {}
.section__inner--contacts {}
.contacts {}
.contacts__row {}
.contacts__row--office-feedback {
    margin: -4rem -(4rem / 2) 4rem -(4rem / 2);

    &:last-child {
        margin-bottom: 0;
    }
}
.contacts__col {}
.contacts__col--office {
    padding: 4rem (4rem / 2) 0 (4rem / 2);
}
.contacts__col--feedback {
    padding: 4rem (4rem / 2) 0 (4rem / 2);
}
.contacts__section {
    .vmargin(4rem);
}
.contacts__section-title {
    .vmargin(3rem);
    font: @title-font;
    font-size: 2rem;
    font-weight: 300;
}
.contacts__section-content {
    .vmargin(3rem);
}
.contacts__section-text {
    .vmargin(2rem);
}
.contacts__section-social {
    .vmargin(2rem);
}
.contacts__section-form {
    .vmargin(2rem);
}

.contacts-map {
    width: 100%;
    height: 360px;
}