/* Filter */
.filter {
    background-color: @alt-color;

    .responsive-max(767px, {
        .transition;
        position: fixed;
        z-index: 552;
        right: 100%;
        top: 0;
        width: 200px;
        height: ~"calc(100% + 100px)";
        padding-top: 1.8rem;
        padding-bottom: ~"calc(100px + 1.8rem)";
        display: block;
        overflow-x: hidden;
        overflow-y: auto;

        & + .filter-toggle {
            transform: translate3d(0,0,0);
        }

        &.active {
            transform: translate3d(200px,0,0) !important; // important when jquery-stickit plugin adds translateZ(0) on unstick
            box-shadow: 0 0 4px rgba(0,0,0,.1);

            & + .filter-toggle {
                transform: translate3d(200px,0,0);
                //box-shadow: 0 0 4px rgba(0,0,0,.1);

                &::before {
                    transform: rotate(360deg);
                }
            }

            & ~ .filter-overlay {
                opacity: 1;
                pointer-events: auto;
            }
        }
    });
}

.filter-overlay {
    .transition;
    z-index: 548;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: ~"calc(100% + 100px)";
    background: rgba(0,0,0,.7);
    opacity: 0;
    pointer-events: none;
}
.filter__section {
    padding: 1.5rem 1.5rem 2.5rem;
}
.filter__section-title {
    .vmargin(1.3rem);
    color: @light-color;
    display: flex;

    .ie & {
        max-height: 2rem;
    }

    &::before {
        .hmargin(5px);
        font-size: 1.3rem;
        height: 1.3rem;
        line-height: 1;
    }
}
.filter__section-content {
    flex: 1;
    .vmargin(1.3rem);
}

.filter__section--tags {

    .filter__section-title {

        &::before {
            content: "#";
            font-size: 1.7rem;
            font-weight: 500;
        }
    }
}
.filter__tags {}

.filter__section--technics {}
.filter__technics {}

.filter__section--colors {}
.filter__colors {}

/* Filter toggle */
.filter-toggle {
    .transition;
    .icon-filter;
    position: fixed;
    z-index: 551;
    display: none;
    left: 0;
    top: 50%;
    margin-top: -20px;
    font-size: 20px;
    background-color: @alt-color;
    width: 40px;
    height: 40px;
    padding: 10px;
    line-height: 1;
    box-shadow: 0 0 4px rgba(0,0,0,.1);

    &::before {
        .transition(all .7s ease .3s);
        display: block;
        transform-origin: center center;
    }

    .responsive-min(768px, {
        display: none;
    });
}

div.filter.jquery-stickit-spacer {
    height: 0 !important;
}