/* Box sizing */
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
*::before,
*::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* Html */
html {
    background: @dark-color;
    height: 100%;
    font-size: 62.5%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .responsive-max(543px, {
        font-size: 56.25%;
    });

    .responsive-min(1200px, {
        margin: 0;
        padding: 0;
    });
}

/* Body */
body {
    .transition;
    font: @text-font;
    color: @text-color;
    min-height: 100%;
    min-width: @min-width;
    max-width: 1920px;
    margin: 0 auto;
    padding: 0;
    overflow-x: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
}

/* Page */
.page {
    overflow-x: hidden;

    .responsive-min(1200px, {
        display: flex;
        flex-direction: column;
    });
}

/* Link */
a {
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

/* Lists */
ul, ol {
    margin: 0;
    padding: 0;

    li {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}

/* Images */
img {
    display: block;
    max-width: 100%;
}

/* Headers */
h1, h2, h3, h4, h5, h6 {
    .vmargin(1em);
    font-family: @title-font-family;
    font-weight: @title-font-weight;
    line-height: @title-line-height;
}

/* Buttons */
button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
    border: none;
    background: #eee;
    padding: .5rem 1rem;
    cursor: pointer;

    &:active,
    &:focus {
        outline: none;
    }
}

/* Sections */
.sections {
    flex: 1 0 auto;
}
.section {
    background-color: #fff;
    padding-top: @section-padding;
    padding-bottom: @section-padding;
}
.section--shadows {
    border-top: 1px solid darken(@alt-color, 5%);
    border-bottom: 1px solid darken(@alt-color, 5%);
    /*
    position: relative;

    &::before {
        .absolute-block(100%,30px);
        left: 0;
        top: 0;
        background-image: linear-gradient(180deg, rgba(119, 119, 119, 0.15) 0%, rgba(119, 119, 119, 0) 100%);
    }

    &::after {
        .absolute-block(100%,30px);
        left: 0;
        bottom: 0;
        background-image: linear-gradient(to top, rgba(119, 119, 119, 0.15) 0%, rgba(119, 119, 119, 0) 100%);
    }
    */
}
.section--shadow-top {
    border-top: 1px solid darken(@alt-color, 5%);
    /*
    position: relative;

    &::before {
        .absolute-block(100%,30px);
        left: 0;
        top: 0;
        background-image: linear-gradient(180deg, rgba(119, 119, 119, 0.15) 0%, rgba(119, 119, 119, 0) 100%);
    }
    */
}
.section--shadow-bottom {
    border-bottom: 1px solid darken(@alt-color, 5%);
    /*
    position: relative;

    &::after {
        .absolute-block(100%,30px);
        left: 0;
        bottom: 0;
        background-image: linear-gradient(to top, rgba(119, 119, 119, 0.15) 0%, rgba(119, 119, 119, 0) 100%);
    }
    */
}
.section--main {
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.section__inner {
    max-width: @max-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.section__header {
    .vmargin(5rem);
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .ie & {
        display: block;
    }
}
.section__title {
    font: @title-font;
    margin: 0;
    margin-right: 2rem;

    .ie & {
        display: inline-block;
    }
}
.section__title--center {
    flex: 0 0 100%;
    text-align: center;
}
.section__title--small {
    font-size: 2rem;
    font-weight: 300;
}
.section__header-icon-button {
    .transition;
    padding: 0;
    background-color: transparent;
    color: @light-color;
    margin-right: 2rem;
    margin-left: -1rem;
    line-height: .4rem;
    font-size: 1.4rem;
    height: 1.4rem;

    &:hover {
        color: @main-color-hover;
    }
}
.section__notifications {
    .vmargin(2.5rem);
}
.section__footer {
    .vmargin(2.5rem);
}
.section__footer--center {
    text-align: center;
}

/* Image & Video links */
.image-link {
    display: block;
    position: relative;
    overflow: hidden;
    color: #fff;

    &::before {
        .transition;
        .absolute-block(100%,100%);
        //.background-rgba(#1c1c1b, .4);
        left: 0;
        top: 0;
        z-index: 1;
    }

    &:hover {

        &::before {
            .background-rgba(#1c1c1b, .3);
        }

        img {
            transform: scale(1.05);
        }
    }

    img {
        .transition;
        width: 100%;
    }
}
.image-link--video {
    //.icon(play, after);

    &::before {
        display: none;
    }

    &::after {
        .transition;
        position: absolute;
        z-index: 2;
        width: 7.5rem;
        height: 7.5rem;
        background: rgba(0,0,0,.6);
        border-radius: 50%;
        overflow: hidden;
        padding: .5rem;
        font-size: 6.5rem;
        text-align: center;
        left: 50%;
        top: 50%;
        margin-left: -3.75rem;
        margin-top: -3.75rem;
        color: #fff;
    }

    &:hover {

        &::after {
            transform: translateY(-5px);
        }

        img {
            transform: none;
        }
    }
}

.back-call {
    .icon-phone;
    position: fixed;
    display: block;
    right: 10px;
    bottom: 10px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    background: @main-color;
    color: #fff;
}

/* Hidden */
.hidden--xs {

    .responsive-max(543px, {
        display: none;
    });
}
.hidden--sm {

    .responsive(544px, 767px, {
        display: none;
    });
}
.hidden--md {

    .responsive(768px, 991px, {
        display: none;
    });
}
.hidden--lg {

    .responsive-min(992px, {
        display: none;
    });
}