/* Sizes */
.sizes {}
.sizes__item {
    .transition;
    display: block;
    color: #777;
    white-space: nowrap;

    &:hover {
        color: @main-color-hover;
    }
}
.sizes__item-tick {
    display: none;

    &:checked + .sizes__item-label {
        color: @dark-color;
        font-weight: bold;
    }
}
.sizes__item-label {
    cursor: pointer;
}