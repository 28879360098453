/* Menu */
.section--menu {
    padding-top: 1rem;
    padding-bottom: 0;

    .responsive-max(767px, {
        padding-top: 0;
    });
}
.section--fixed-menu {

    .responsive-min(768px, {
        background: @alt-color;
        padding-top: 0;
        position: fixed;
        z-index: 1700;
        left: 0;
        top: 0;
        width: 100vw;
        padding-right: 15px;
        box-shadow: 0 6px 5px -6px rgba(0,0,0,.1);

        .menu {
            background: transparent;
        }
    });
}
.section--menu-popup {
    background: @dark-color;

    .responsive-min(768px, {
        background: @dark-color;
    });
}
.section__inner--menu {}
.menu {
    background: @alt-color;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.menu--dark {
    .background-rgba(@alt-color, .05);
    color: #fff;
}

.menu-overlay {
    .transition;
    z-index: 998;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: ~"calc(100% + 100px)";
    background: rgba(255,255,255,.9);
    opacity: 0;
    pointer-events: none;
}

.menu--main {

    .responsive-max(767px, {
        .transition;
        position: fixed;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        left: 100%;
        top: 0;
        width: 220px;
        height: ~"calc(100% + 100px)";
        padding-top: 1.8rem;
        padding-bottom: ~"calc(100px + 1.8rem)";
        background: @dark-color;
        color: #fff;
        overflow-x: hidden;
        overflow-y: auto;

        & + .menu-toggle-container {
            transform: translate3d(0,0,0);
        }

        &.active {
            transform: translate3d(-220px,0,0);
            box-shadow: 0 0 4px rgba(0,0,0,.1);

            & + .menu-toggle-container {
                transform: translate3d(-220px,0,0);
                box-shadow: 0 0 4px rgba(0,0,0,.1);
            }

            & ~ .menu-overlay {
                opacity: 1;
                pointer-events: auto;
            }
        }
    });

    .menu__list {

        .responsive-max(767px, {
            .vmargin(2rem);
            display: block;
        });
    }

    .menu__item {

        .responsive-max(767px, {
            font-size: 1.4rem;
            display: block;
            height: 36px;
            line-height: 36px;
            border-bottom: 1px solid rgba(255,255,255,.03);
        });

        .responsive-max(359px, {
            height: 30px;
            line-height: 30px;
        });
    }

    .menu__phone {

        .responsive-max(767px, {
            .vmargin(1rem);
            flex: 0 0 auto;
            margin-left: auto;
            margin-right: auto;
            height: auto;
            line-height: 1;
        });
    }
}
.menu__list {
    flex: 1 0 auto;
    margin-left: -1rem;
    margin-right: -1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    .responsive-max(991px, {
        padding-left: .5rem;
        padding-right: .5rem;
    });
}
.menu__item {
    .transition;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
    display: block;
    text-align: center;

    &:hover {
        color: @main-color-hover;
    }

    .responsive-max(991px, {
        padding-left: .5rem;
        padding-right: .5rem;
    });
}
.menu__item--current {
    font-weight: 500;
}
.menu__item--homepage {

    .responsive-min(768px, {
        display: none;
    });
}
.menu__links {
    .vmargin(1rem);
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .responsive-min(768px, {
        display: none;
    });
}
.menu__link {
    .transition;
    padding-left: .5rem;
    padding-right: .5rem;
    font-size: 1.4rem;
    line-height: 1;

    &:hover {
        color: @main-color-hover;
    }
}
.menu__buttons {
    .vmargin(1rem);
    flex: 0 0 auto;
    text-align: center;
    margin: -1rem;

    .responsive-min(768px, {
        display: none;
    });
}
.menu__button {
    flex: 1 1 100%;
    display: flex;
    margin: 1rem;
}
.menu__phone {
    .transition;
    font: @title-font;
    font-size: 1.6rem;
    height: 40px;
    line-height: 40px;
    margin-left: 3rem;
    text-align: center;
    display: block;

    &:hover {
        color: @main-color-hover;
    }

    /*.responsive(768px, 991px, {
        display: none;
    });*/
}

/* Menu toggle */
.menu-toggle-container {
    .transition;
    position: fixed;
    z-index: 999;
    top: 10px;
    right: 0;
    text-align: right;

    .responsive-min(768px, {
        display: none;
    });
}
.menu-toggle {
    .transition;
    transform: translate3d(0,0,0);
    background: @dark-color;
    display: block;
    position: relative;
    height: 40px;
    width: 40px;
    margin: 0;
    padding: 0;
    cursor: pointer;
}
.menu-toggle__checkbox {
    display: none;

    &:checked {

        & + .menu-toggle__icon {

            span {

                &:nth-child(1),
                &:nth-child(4) {
                    top: .9rem;
                    width: 0%;
                    left: 50%;
                }

                &:nth-child(2) {
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
            }
        }
    }
}
.menu-toggle__icon {
    .transition(all .2s .3s ease-in-out);
    transform: translate3d(0,0,0);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 24px;
    height: 20px;

    span {
        .transition(all .2s .3s ease-in-out);
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        opacity: 1;
        left: 0;
        background: #fff;

        &:nth-child(1) {
            top: 1px;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 9px;
        }

        &:nth-child(4) {
            top: 17px;
        }
    }
}