/* Collection */
.section--collection {
    background: #fff url(../img/bg-collector-prints.jpg) no-repeat center bottom;
    min-height: 910px;
    position: relative;
    padding-top: 0;
    padding-bottom: 14rem;

    .responsive-max(991px, {
        padding-bottom: 24rem;
    });

    .responsive-max(767px, {
        padding-top: 5rem;
        padding-bottom: 44rem;
    });
}
.section__inner--collection {}
.collection {
    width: 757px;
    height: 394px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    .responsive-max(787px, {
        width: 583px;

        .collection__print--7 {
            display: none;
        }
    });

    .responsive-max(613px, {
        width: 543px;

        .collection__print--6 {
            display: none;
        }
    });

    .responsive-max(583px, {
        width: 489px;

        .collection__print--5 {
            display: none;
        }

        .collection__print--6 {
            display: block;
            top: 174px;
            left: 370px;
        }
    });

    .responsive-max(519px, {
        width: 356px;

        .collection__print--6 {
            display: none;
        }

        .collection__print--4 {
            display: none;
        }
    });

    .responsive-max(386px, {
        width: auto;
        height: 367px;

        .collection__print--3 {
            width: 42%;
            bottom: auto;
            top: 69%;
            right: 5%;
            left: auto;
        }

        .collection__print--2 {
            width: 51%;
            right: 0;
            top: 0;
            left: auto;
        }

        .collection__print--1 {
            width: 44%;
            top: 25%;
            bottom: auto;
        }
    });

    .responsive-max(353px, {
        width: auto;
        height: 347px;
    });

    .responsive-max(333px, {
        width: auto;
        height: 327px;
    });
}
.collection__print {
    position: absolute;
    overflow: hidden;
}
.collection__print--1 {
    bottom: 50px;
    left: 0;
    max-width: 159px;
    max-height: 221px;
}
.collection__print--2 {
    top: 17px;
    left: 175px;
    max-width: 181px;
    max-height: 244px;
}
.collection__print--3 {
    bottom: 0;
    left: 189px;
    max-width: 150px;
    max-height: 117px;
}
.collection__print--4 {
    top: 0;
    left: 370px;
    max-width: 119px;
    max-height: 165px;
}
.collection__print--5 {
    top: 174px;
    left: 370px;
    max-width: 173px;
    max-height: 136px;
}
.collection__print--6 {
    top: 56px;
    left: 506px;
    max-width: 78px;
    max-height: 108px;

    &[data-recommend] {

        &::after {
            font-size: .9rem;
        }
    }
}
.collection__print--7 {
    top: 109px;
    left: 598px;
    max-width: 159px;
    max-height: 221px;
}

.collection--home {

    .collection__print {
        max-height: none;
    }
}