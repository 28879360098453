.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 100%;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: -@row-gutter -(@col-gutter / 2) @row-gutter -(@col-gutter / 2);
  padding: 0;

  &:last-child {
    margin-bottom: 0;
  }

  .variant(no-gutters; {
    margin: 0;
  });
}