.slick-arrow {
    .transition;
    position: absolute;
    z-index: 1;
    display: block;
    top: 50%;
    margin-top: -25px;
    font-size: 0;
    line-height: 0;
    background: none;
    border: none;
    color: @main-color;
    cursor: pointer;
    padding: 0;
    width: 50px;
    height: 50px;
    background: none;

    &::before {
        font-size: 22px;
    }

    &:hover {
        color: @main-color-hover;
    }

    &:focus,
    &:active {
        outline: none;
    }

    &.slick-hidden {
        display: none;
    }

    &.slick-disabled {
        opacity: .3;
        cursor: default;

        &:hover {
            color: @main-color;
        }
    }
}

.slick-prev {
    .icon-prev;
    left: 0;
}

.slick-next {
    right: 0;
    .icon-next;
}

.slick-dots {
    position: absolute;
    display: block;
    width: 100%;
    bottom: 20px;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;

    li {
        display: inline-block;
        margin-left: 3px;
        margin-right: 3px;

        button {
            .transition;
            display: block;
            padding: 0;
            background: @main-color;
            font-size: 0;
            line-height: 0;
            width: 12px;
            height: 12px;
            overflow: hidden;
            color: #fff;

            &:hover {
                color: @main-color;
            }

            &:focus,
            &:active {
                outline: none;
            }

            &:before {
                font-size: 12px;
            }
        }

        &.slick-active {

            button {
                color: @main-color;
            }
        }
    }
}