/* surrounding tag container */
.tag-editor {
    .form__input;
    list-style-type: none;
    overflow: hidden;
    cursor: text;
    padding: 0;
    padding-right: 1px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .form--popup & {
        .vmargin(2rem);
        .form__input--large;
        padding: 0;
        padding-right: 1px;
        background: transparent;
        border: 2px solid #787878;
        color: #fff;

        &:hover {
            border-color: @main-color-hover;
        }
        &:active,
        &:focus {
            border-color: #fff;
            outline: none;
        }
    }
}

/* core styles usually need no change */
.tag-editor li {
    display: flex;
    flex: 1 1 0%;
    margin: 1px;

    &:first-child {
        display: none;
    }

    &:last-child {
        margin-right: 50px;
    }
}
.tag-editor div {
    padding: 0 4px;
}
.tag-editor .placeholder {
    padding: 7px 10px;
    color: #727274;

    div {
        width: 100%;
        float: none;
        padding: 0;
    }

    &:nth-child(2) {
        padding-left: 3.5rem;
        //text-align: center;
        width: ~"calc(100% - 6px)";
    }

    &:last-child {
        margin-right: 0;
    }
}
.tag-editor .tag-editor-spacer {
    display: none;
}
.tag-editor input {
    vertical-align: inherit;
    border: 0;
    outline: none;
    padding: 0;
    margin: 0;
    cursor: text;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    font-style: inherit;
    box-shadow: none;
    background: none;
    color: #fff;
}
/* hide original input field or textarea visually to allow tab navigation */
.tag-editor-hidden-src {
    position: absolute !important;
    left: -99999px;
}
/* hide IE10 "clear field" X */
.tag-editor ::-ms-clear {
    display: none;
}

/* tag style */
.tag-editor .tag-editor-tag {
    border-radius: 16px 0 0 16px;
    flex: 1;
    text-align: left;
    padding: 7px 0 7px 10px;
    color: @text-color;
    background: @light-color;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
}

/* delete icon */
.tag-editor .tag-editor-delete {
    background: @light-color;
    cursor: pointer;
    border-radius: 0 16px 16px 0;
    padding: 7px 10px;
}
.tag-editor .tag-editor-delete i {
    line-height: 18px;
    display: inline-block;
}
.tag-editor .tag-editor-delete i:before {
    color: @text-color;
    content: "×";
    font-style: normal;
}
.tag-editor .tag-editor-delete:hover i:before {
    color: @main-color-hover;
}
.tag-editor .tag-editor-tag.active+.tag-editor-delete,
.tag-editor .tag-editor-tag.active+.tag-editor-delete i {
    visibility: hidden;
    cursor: text;
}

.tag-editor .tag-editor-tag.active {
    background: none !important;
}