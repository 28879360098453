/* Products masonry */
.products-list {
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    .responsive-max(850px, {
        margin-left: -1rem;
        margin-right: -1rem;
    });

    .responsive-max(530px, {
        margin-left: -.7rem;
        margin-right: -.7rem;
    });
}
.products-list--small {
    margin-bottom: -2rem;

    .products-list__item {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 2rem;
        max-width: none;

        .responsive-max(530px, {
            padding-left: .7rem;
            padding-right: .7rem;
            margin-bottom: 1.4rem;
        });
    }

    .product::after {
        display: none;
    }
}
.products-list__sizer,
.products-list__item {
    width: 33.3333%;

    /*.responsive-max(850px, {
        width: 33.3333%;
    });*/

    .responsive-max(530px, {
        width: 50%;
    });
}
.products-list__sizer--3,
.products-list__item--3 {
    width: 25%;

    .responsive-max(850px, {
        width: 33.3333%;
    });

    .responsive-max(530px, {
        width: 50%;
    });
 }
.products-list__sizer--25,
.products-list__item--25 {
    width: 20%;

    .responsive-max(850px, {
        width: 50%;

        &:nth-child(n+6) {
            display: none;
        }
    });

    /*.responsive-max(530px, {
        width: 50%;
    });*/

    .responsive-max(380px, {
        width: 100%;
    });
 }
.products-list__item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    max-width: none;

    /*.product::after {
        display: none;
    }*/

    .responsive-max(850px, {
        padding-left: 1rem;
        padding-right: 1rem;
    });

    .responsive-max(530px, {
        padding-left: .7rem;
        padding-right: .7rem;
    });
}
.products-list__message {
    padding-top: 3rem;
    padding-bottom: 3rem;
    font-size: 2rem;
    text-align: center;
}
.products-list__info {
    .transition;
    position: absolute;
    z-index: 600;
    transform: translate3d(0,0,0);
    right: 1.5rem;
    width: ~"calc(100vw - 4.5rem)";
    max-width: ~"calc(" @max-width ~" - 3rem)";
    margin-top: -1.7rem;
    //box-shadow: 0 0 1px rgba(0,0,0,.3);
    box-shadow: 0 0 10px rgba(0,0,0,.3);
    opacity: 0;
    visibility: hidden;

    .responsive-max(850px, {
        right: 1rem;
    });

    .responsive-max(767px, {
        z-index: 500;
        max-width: none;
    });

    .responsive-max(530px, {
        right: .7rem;
        width: ~"calc(100% - 1.4rem)";
    });
}
.products-list__footer {
    .vmargin(3rem);
    text-align: center;
}