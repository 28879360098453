@font-face {
    font-family: "icons";
    src: url('../fonts/icons.woff2') format('woff2'),
         url('../fonts/icons.woff') format('woff'),
         url('../fonts/icons.ttf') format('truetype');
}

.icon-base-pseudo {
    font-family: "icons";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
    text-decoration: none;
    text-transform: none;
    line-height: 1;
}

.icon-char(@filename) {
    @behance: "\E001";
    @cart: "\E002";
    @close: "\E003";
    @download: "\E004";
    @eye: "\E005";
    @facebook: "\E006";
    @filter: "\E007";
    @gift: "\E008";
    @google-plus: "\E009";
    @instagram: "\E00A";
    @key: "\E00B";
    @mail: "\E00C";
    @menu: "\E00D";
    @next: "\E00E";
    @painting: "\E00F";
    @pencil: "\E010";
    @phone: "\E011";
    @pin: "\E012";
    @prev: "\E013";
    @search: "\E014";
    @select: "\E015";
    @tick: "\E016";
    @twitter: "\E017";
    @vkontakte: "\E018";
    @wallet: "\E019";
    
    content: @@filename;
}

.icon(@filename, @insert: before) {
    @pseudo-selector: ~":@{insert}";

    &@{pseudo-selector} {
        &:extend(.icon-base-pseudo);
        .icon-char(@filename);
    }
}

.icon-behance {
    .icon(behance);
}
.icon-cart {
    .icon(cart);
}
.icon-close {
    .icon(close);
}
.icon-download {
    .icon(download);
}
.icon-eye {
    .icon(eye);
}
.icon-facebook {
    .icon(facebook);
}
.icon-filter {
    .icon(filter);
}
.icon-gift {
    .icon(gift);
}
.icon-google-plus {
    .icon(google-plus);
}
.icon-instagram {
    .icon(instagram);
}
.icon-key {
    .icon(key);
}
.icon-mail {
    .icon(mail);
}
.icon-menu {
    .icon(menu);
}
.icon-next {
    .icon(next);
}
.icon-painting {
    .icon(painting);
}
.icon-pencil {
    .icon(pencil);
}
.icon-phone {
    .icon(phone);
}
.icon-pin {
    .icon(pin);
}
.icon-prev {
    .icon(prev);
}
.icon-search {
    .icon(search);
}
.icon-select {
    .icon(select);
}
.icon-tick {
    .icon(tick);
}
.icon-twitter {
    .icon(twitter);
}
.icon-vkontakte {
    .icon(vkontakte);
}
.icon-wallet {
    .icon(wallet);
}
